@import 'src/styles/mixins/common-auto-complete';

.recipient-search-item {
  @include auto-complete-item;

  .recipient-logo {
    @include auto-complete-item-logo;
  }

  .recipient-info {
    @include auto-complete-item-details;
  }
}
