.guarantee-info-section {
  .header {
    display: flex;
    margin-bottom: 16px;
    margin-top: 4px;
    img {
      height: 65px;
      margin-right: 10px;
    }
    h2 {
      margin-bottom: 16px;
      flex: 1;
    }
    & > div {
      margin-top: 2px;
      align-self: baseline;
    }
  }
  .text {
    font-size: 16px;
  }
}
