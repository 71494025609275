@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';
@import 'src/styles/mixins/common-detail-pages';

.close-account {
  .form-container {
    @include form-container;
    margin-top: 16px;
  }

  .intro-close-account {
    @include detail-pages-intro-container;
  }

  @include remove-sticky-on-input-focus;
}
