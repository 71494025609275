@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/palette';

.checkbox-container {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  & input {
    position: absolute;
    opacity: 0;
    margin: 0;
  }

  label {
    font-size: 14px;
    color: $charcoal;
    line-height: 21px;

    @include mobile {
      font-size: 16px;
      line-height: 23px;
    }

    @include desktop {
      &:hover {
        cursor: pointer;
      }
    }

    &:before {
      height: 18px;
      width: 18px;
      background-color: $white;
      border: 1px solid $nobel-gray;
      border-radius: 3px;
      transition: background-color $transition-time;
      cursor: pointer;
      content: '';
      display: block;
      float: left;

      @include mobile {
        height: 20px;
        width: 20px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      cursor: pointer;
    }

    @include desktop {
      &:hover {
        &:before {
          border-color: $dark-gray;
        }
      }
    }
  }

  input[type='checkbox']:checked {
    & + label {
      &:before {
        background-color: $check-blue;
        border-color: transparent;
      }

      &:after {
        display: inline-block;
        left: 6.5px;
        top: 2px;
        width: 5px;
        height: 11px;
        border: solid $white;
        border-width: 0 2.5px 2.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);

        @include mobile {
          left: 7.5px;
          top: 2px;
          width: 5px;
          height: 12px;
        }
      }
    }
  }

  input[type='checkbox']:disabled {
    & + label {
      &:before {
        cursor: not-allowed;
        background-color: $helpTip-gray;
        border-color: $nobel-gray;
      }

      &:after {
        cursor: not-allowed;
        border-color: transparent !important;
      }
    }
  }

  &.has-label {
    label:before {
      margin-right: 16px;

      @include mobile {
        margin-right: 12px;
      }
    }
  }
}
