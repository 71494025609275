@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.add-button {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid $light-gray;
  border-radius: 24px;
  stroke-width: 1px;
  background-color: $white;
  color: $charcoal;

  font-size: 16px;
  font-family: $body-font-mobile;
  cursor: pointer;
  width: fit-content;
  transition: background-color $transition-time;

  @include desktop {
    font-size: 14px;
    font-family: $body-font-desktop;
  }

  &:focus,
  &:hover {
    background-color: $cta-bg-gray;
  }

  .icon {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
}
