@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.notification-item {
  transition: border-color $transition-time;

  @include border-bottom-extended;

  .wrapper {
    display: flex;
    flex-grow: 1;
    gap: 16px;

    .details {
      padding-block: 16px;
      flex-grow: 1;
      cursor: pointer;

      .flex-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }

    .notification-checkbox {
      position: relative;
      top: 36px;
      height: 100%;

      &.small-box {
        top: 24px;
      }
    }
    .information {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        font-size: 16px;
      }

      .name.highlight {
        color: $devils-gray;
      }

      .category {
        font-size: 14px;
        color: $regular-gray;
      }

      .payment-details {
        display: flex;
        gap: 4px;
        font-size: 14px;
        color: $regular-gray;

        .amount {
          white-space: nowrap;
        }

        .inactive-badge {
          margin-top: auto;
          background-color: $nobel-gray;
          padding: 2px 10px;
          border-radius: 3px;
          color: $dark-gray;
        }
      }
    }

    .carret-color {
      color: $nobel-gray;
    }
  }

  .warnings {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;

    padding-top: 10px;
    font-size: 14px;

    &.error {
      color: $error-red;
    }

    &.alert {
      color: $alert-orange;
    }

    &.check {
      color: $nobel-green;
    }

    &.info {
      color: $regular-gray;
    }

    .info-icon {
      width: 16px;
      height: 16px;
    }
  }

  &.highlight {
    position: relative;
    background-color: $alert-gray;
    margin-inline: -16px;
    padding-inline: 16px;

    .checkbox-container label::before {
      background-color: transparent !important;
      border-color: $nobel-gray;
    }
    .checkbox-container label::after {
      border-color: transparent;
    }
  }

  &:first-child {
    &.highlight {
      border-radius: 5px 5px 0 0;
    }
  }
  &:last-child {
    &.highlight {
      border-radius: 0 0 5px 5px;
    }
  }
  &:only-child {
    &.highlight {
      border-radius: 5px;
    }
  }
}
