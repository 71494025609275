@mixin error-text {
  margin-top: 8px;
  color: $error-red;
  font-size: 12px;

  @include mobile {
    margin-top: 4px;
    font-size: 14px;
  }
}

@mixin form-label {
  font-size: 16px;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 8px;
  }
}

@mixin form-container {
  padding: 0 16px;

  .description {
    font-size: 16px;
    margin-bottom: 44px;

    @include desktop {
      margin-bottom: 48px;
    }
  }

  .form-group {
    margin-bottom: 44px;

    @include desktop {
      margin-bottom: 48px;
    }

    .form-header {
      color: $charcoal;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .form-label {
      @include form-label;
    }
    .form-input {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}

@mixin switching-date-group {
  display: flex;
  flex-direction: column;

  .date-wrapper {
    width: 100%;

    .asap-row {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }
  }
  .switching-info {
    margin-top: 8px;
    align-self: baseline;
    font-size: 14px;
    color: $devils-gray;
  }
}

@mixin remove-sticky-on-input-focus($selected_class: '.action-buttons.sticky') {
  @include mobile {
    :has(input:focus:not([type='checkbox'])) #{$selected_class} {
      position: static;
    }
  }
}
