@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.standing-order-details-tab {
  .form-container {
    @include form-container;

    & .switching-date {
      @include switching-date-group;
    }

    .form-input {
      & > .euro-text {
        align-self: start;
        margin-top: 14px;
        margin-left: 16px;
        font-size: 18px;

        @include mobile {
          margin-top: 19px;
        }
      }

      & .error-text {
        width: max-content;
      }
    }
  }
}
