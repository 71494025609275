@import 'src/styles/palette';

.tooltipWithImg-wrapper {
  position: relative;

  .img {
    cursor: pointer;
  }

  .img:hover ~ .tooltip {
    display: block;
  }

  .tooltip:hover {
    display: block;
  }

  .tooltip {
    display: none;
    position: absolute;
    background: $white;
    color: $dark-gray !important;
    width: max-content;
    max-width: 15rem;
    padding: 0.75rem;
    height: max-content;
    border-radius: 3px;
    border: 1px solid $check-blue;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    right: 80px;
    top: 0;
    z-index: 3;

    .title {
      font-weight: bolder;
      margin-bottom: 10px;
    }
    & p {
      font-size: 12px;
      text-align: left;

      b {
        font-family: Verdana;
        font-weight: bolder;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 11px solid $check-blue;
      position: absolute;
      right: -11px;
      top: 27px;
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $white;
      position: absolute;
      right: -10px;
      top: 28px;
    }
  }
}
