@import 'src/styles/palette';
@import 'src/styles/screen-types';

@mixin detail-pages-bottom-margin {
  margin-bottom: 24px;
}

@mixin top-container-shadow {
  box-shadow: 0px 1px 2px rgba($black, 0.2);

  @include desktop {
    box-shadow: 0px 1px 5px rgba($black, 0.1);
  }
}

@mixin container-bottom-margin-zero {
  border-radius: 3px 3px 0px 0px;
  margin-bottom: 0;
}

@mixin details-page-headline {
  font-size: 22px;
}

@mixin detail-pages-intro-container {
  .container {
    @include detail-pages-bottom-margin;
    @include top-container-shadow;

    @include desktop {
      padding: 24px 48px;
    }
  }
}

@mixin notif-and-so-details {
  @include detail-pages-intro-container;

  &.bottom-margin-zero {
    .container {
      @include container-bottom-margin-zero;
    }

    .intro {
      margin-bottom: 0;
    }
  }
}
