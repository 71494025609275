@import 'src/styles/screen-types';
@import 'src/styles/palette';

.faq-section {
  h2 {
    margin-top: 0;
  }

  .all-questions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    span {
      font-size: 14px;
      line-height: 17px;

      @include mobile {
        font-size: 16px;
        line-height: 18px;
      }
      color: $check-blue;
    }
  }
}
