@import 'src/styles/palette';

#desktop-auto-complete {
  position: relative;
  border: 1px solid $cta-bg-blue;
  border-radius: 5px;
  box-sizing: border-box;
  background: $white;

  &.error {
    border-color: $error-red;
  }
  input {
    border: none !important;
    border-color: transparent !important;
    cursor: pointer;
  }

  .result-box {
    position: absolute;
    z-index: 99;
    top: 110%;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    border: 1px solid $alto-gray;
    border-radius: 5px;
    background: $white;
    width: 100%;

    font-size: 16px;
    max-height: 365px;
    overflow: auto;

    &.empty {
      padding: 0;
    }

    & > div:not(.spinner-loader) {
      cursor: pointer;
      border-bottom: 1px solid $alto-gray;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.error {
    .result-box {
      top: 160%;
    }
  }

  .spinner {
    margin: 2rem auto !important;
    width: 50px !important;
    height: 50px !important;
  }
}
