@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.confirmation-modal {
  display: flex;
  flex-direction: column;
  max-height: 60vh;

  .content {
    white-space: pre-line;
    overflow: auto;

    @include mobile {
      padding: 16px;
      box-sizing: border-box;
    }
  }

  .desktop-buttons {
    @include mobile {
      display: none;
    }
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;

    button {
      margin: 0;
      min-width: 165px;
      max-width: max-content;
      font-size: 14px;
    }
  }

  .mobile-button {
    @include desktop {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 57px;
    border-top: 1px solid $light-gray;
    color: $check-blue;
    font-weight: 600;
    font-size: 18px;

    .blue-spinner {
      @include button-spinner(rgba($check-blue, 0.2), $check-blue);
    }
  }
}
