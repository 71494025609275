@mixin cta-button-center {
  .cta-button {
    max-width: max-content;
    min-width: 250px;
    margin-inline: auto;
  }
}

@mixin confirmation-modal-content {
  & > :first-child {
    margin-bottom: 16px;

    @include desktop {
      font-size: 18px;
    }
    @include mobile {
      font-size: 16px;
      font-weight: 600;
    }
  }

  & > :not(:first-child) {
    margin-bottom: 10px;

    @include desktop {
      font-size: 14px;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

@mixin border-bottom-extended {
  &::after {
    content: '';
    display: block;
    margin-inline: -16px;
    border-bottom: 1px solid $light-gray;
  }

  &:last-child::after {
    border-bottom: none;
  }
}

@mixin button-spinner($border-color: rgba(255, 255, 255, 0.7), $primary-color: $white) {
  position: relative;
  width: 15px;
  height: 15px;
  border: 4px solid;
  margin: 0;
  margin-left: 10px;
  border-color: $border-color;
  border-radius: 50%;
  &::before {
    content: '';
    position: absolute;
    width: 15px;
    left: -4px;
    top: -4px;
    height: 15px;
    border: 4px solid;
    margin: 0;
    border-color: $primary-color transparent transparent;
    border-radius: 50%;
    animation: spin 1.8s linear infinite;
  }
}

@mixin description-bubble {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #ecf7fd;
  border-radius: 30px 30px 30px 0;
  line-height: 1.43;

  h3 {
    font-size: 16px;
    margin: 0;
  }
  p:not(:first-child) {
    margin-top: 10px;
  }
}

@mixin sticky-from-top($top: 0) {
  position: sticky;
  top: $top;
  z-index: 99;
}
