@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';
@import 'src/styles/mixins/common-blocks';

.dac-page {
  color: $charcoal;

  @include desktop {
    padding-bottom: 48px;
  }

  .dac-container {
    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
    }

    .hidden-while-loading {
      display: none;
    }
  }

  .bank-search-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .bank-search {
      position: relative;
      padding: 4px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 57px;
        background-color: $highlight-yellow;
        width: 100%;
        left: 0;
        top: 0;
        border-radius: 5px;
        z-index: 1;

        @include mobile {
          height: 66px;
        }
      }

      .auto-complete {
        position: relative;
        z-index: 2;
      }

      .error-text {
        @include error-text;
      }
    }
  }

  .iban-equal-modal {
    @include confirmation-modal-content;
  }
}
