@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.review-transaction {
  @include border-bottom-extended;

  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-block: 16px;
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 65%;

    .name {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .info {
      font-size: 14px;
      color: $regular-gray;
    }

    .amount {
      font-size: 14px;
      white-space: nowrap;
      color: $regular-gray;
    }
  }

  .extra-info {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 1px;

    .date {
      text-align: right;
      color: $charcoal;
      font-size: 16px;
    }
  }
}
