@import 'src/styles/palette';
@import 'src/styles/variables';

.toggle {
  .toggle-click {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: $check-blue;
    margin-top: 16px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    svg {
      margin-left: 6px;
      transition: transform $transition-time;
    }
  }

  .toggle-body {
    margin-top: 20px;
  }
}
