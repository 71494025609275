@import 'src/styles/screen-types';
@import 'src/styles/variables';
@import 'src/styles/palette';

.orders-section {
  .section-header {
    margin-bottom: 16px;
  }

  .recent-section {
    .header {
      font-size: 18px;
      margin: 24px 0 16px;

      @include desktop {
        font-size: 20px;
      }
    }
  }

  .add-order {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}
