@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';
@import 'src/styles/mixins/common-blocks';

.action-buttons {
  margin-bottom: 8px;
  padding: 16px;

  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }

  transition:
    background-color $transition-time,
    box-shadow $transition-time;
  &.white-bg {
    background-color: $white;
    box-shadow: 0px -1px 2px rgba($black, 0.2);
  }

  @include desktop {
    border-radius: 3px;
    margin-bottom: 16px;
    padding: 24px 48px;
    @include cta-button-center;
  }

  // Overrides Button class
  .cta-button {
    margin-bottom: 10px;
  }

  &.alt {
    background: $white;
    padding-left: 2px;
    padding-right: 2px;
  }

  .cancel-button {
    color: $check-blue;
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
    margin: auto;

    @include desktop {
      font-size: 14px;
    }
  }

  .form-error {
    @include error-text;
    text-align: center;
    margin-bottom: 8px;
  }
}
