@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-detail-pages';

.welcome-section-account-closing {
  display: flex;
  flex-direction: column;

  .text {
    @include details-page-headline;
    color: $charcoal;
    text-decoration: none solid rgb(51, 51, 51);
    margin-bottom: 16px;
  }
}
