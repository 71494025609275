@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.not-possible {
  .content {
    text-align: center;
    padding: 0 15px;

    img {
      margin-top: 50px;
      margin-bottom: 30px;
      height: 260px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: $black;
      margin-bottom: 15px;
    }

    button {
      margin-bottom: 30px;
    }

    @include desktop {
      @include cta-button-center;
    }
  }
}
