@import 'src/styles/palette';

.xs2a-select {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  select {
    background-color: $white;
    font-size: 14px;
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 16px 16px 0px 16px;
    background-color: $white;
    height: 47px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: $charcoal;

    @include mobile {
      font-size: 18px;
      height: 58px;
    }

    @include desktop {
      &:hover {
        border-color: $dark-gray;
      }
    }

    &:focus {
      border-color: $check-blue;
    }
  }

  label {
    position: absolute;
    pointer-events: none;
    top: 6px;
    left: 17px;
    font-size: 12px;
    opacity: 1;
    color: $regular-gray;
    transition: 0.2s ease all;

    @include mobile {
      font-size: 13px;
    }
  }

  .svg-div {
    position: absolute;
    align-items: center;
    display: flex;
    height: 100%;
    pointer-events: none;
    right: 16px;
    transform: rotate(180deg);

    polygon {
      fill: $nobel-gray;
    }
  }
}
