@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.toggle-section {
  .toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: $check-blue;
    margin-top: 16px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    svg {
      transition: transform $transition-time;
      margin-left: 6px;
    }
  }

  @include mobile {
    & > .container,
    &-content > .container {
      box-shadow: none;
    }

    &-content > .container:last-child {
      margin-bottom: 8px;
    }
  }
}
