@import 'src/styles/palette';

.spinner-loader {
  width: 50px;
  height: 50px;
  border: 10px solid;
  margin: 30px auto;
  border-color: $spinner-blue transparent $spinner-blue transparent;
  border-radius: 50%;
  animation: spin 1.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
