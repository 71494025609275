@import 'fintec_variables.styles';

.xs2a-captcha {
  img {
    // captcha image
    margin: 0 auto;
    display: block;
  }

  input {
    margin-top: 20px;
  }

  label {
    position: absolute;
    bottom: 55px;
  }
}
