@import 'src/styles/palette';

.xs2a-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 20px;

  & .xs2a-error {
    padding: 0 40px;
  }

  /* Hide the browser's default checkbox */
  & input {
    opacity: 0;
    margin: 0;
    height: 0;
    width: 0;
  }

  & label {
    padding-left: 36px;
    font-size: 14px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: $white;
      border: 1px solid $nobel-gray;
      border-radius: 3px;
      transition: background-color $transition-time;
      cursor: pointer;
      content: '';

      @include mobile {
        height: 20px;
        width: 20px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      cursor: pointer;
    }

    @include desktop {
      &:hover {
        &:before {
          border-color: $dark-gray;
        }
      }
    }
  }

  input[type='checkbox']:checked {
    & + label {
      &:before {
        background-color: $check-blue;
        border-color: transparent;
      }

      &:after {
        display: inline-block;
        left: 6.5px;
        top: 2px;
        width: 5px;
        height: 11px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);

        @include mobile {
          left: 7.5px;
          top: 2px;
          width: 5px;
          height: 12px;
        }
      }
    }
  }
}
