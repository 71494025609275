@import 'src/styles/palette';

.inner-border-container {
  padding: 0px 16px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  stroke-width: 1;

  &.bg-white {
    background-color: $white;
  }
}
