@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.address-input {
  width: 100%;

  .street-streetNo-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .input-field {
      width: 100%;
      display: flex;
      gap: 8px;
    }

    .error-field {
      @include error-text;
    }

    .hint-field {
      @include error-text;
      color: $alert-orange;
    }
  }

  .postalCode-city-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .input-field {
      width: 100%;
      display: flex;
      gap: 8px;
    }

    .error-field {
      @include error-text;
    }
  }
}
