@import 'src/styles/mixins/common-blocks';
@import 'src/styles/mixins/common-detail-pages';
@import 'src/styles/mixins/common-input-blocks';

.standing-orders-details {
  .intro-standing-order {
    @include notif-and-so-details;

    h2 {
      @include details-page-headline;
    }
  }

  .stick-to-top {
    .tabs-section {
      @include sticky-from-top;
    }
  }

  .stick-to-top-50 {
    .tabs-section {
      @include sticky-from-top(50px);
    }
  }

  .reset-button:has(~ .stick-to-top-50) {
    box-shadow: none;
  }

  @include remove-sticky-on-input-focus;
}
