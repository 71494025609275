@import 'src/styles/screen-types';
@import 'src/styles/variables';
@import 'src/styles/palette';

.descriptions {
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .title-text {
      font-size: 20px;
    }

    .title-dropdown {
      display: flex;
      align-items: center;
      .dropdown-icon {
        transition: transform $transition-time;
        transform: rotate(180deg);
        polygon {
          fill: $nobel-gray;
        }
        &.open {
          transform: rotate(0deg);
        }
      }
    }
  }

  .body {
    margin-top: 20px;
    .title {
      text-align: center;
      font-size: 20px;
    }
    .sub-title {
      margin-top: 14px;
      font-size: 16px;
      text-align: center;
    }
    .bullets {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .line {
        display: flex;
        margin-top: 23px;
        align-items: center;

        svg {
          color: $check-blue;
          height: 70px;
        }
        p {
          margin-left: 21px;
          font-size: 14px;
          span {
            font-weight: bold;
          }
        }
      }
    }

    .trust {
      display: flex;
      align-items: center;
      margin-top: 23px;
      p {
        font-size: 14px;
      }

      img {
        height: 40px;
        margin-left: 17px;
      }
    }
  }
}
