@import 'fonts';
@import 'fintec_variables.styles';

.xs2a-error {
  @include fintec-error;

  p {
    padding: 0;
    margin: 0;
  }
}
