@import 'src/styles/palette';
@import 'src/styles/variables';
@import 'src/styles/screen-types';

.chip-button {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 2rem;
  background-color: $white;
  color: $charcoal;
  cursor: pointer;
  transition:
    border-color $transition-time,
    background-color $transition-time;
  border: 1px solid $light-gray;
  font-size: 16px;
  font-family: $body-font-mobile;

  @include desktop {
    font-size: 14px;
    font-family: $body-font-desktop;
  }

  &:hover {
    border-color: $charcoal;
  }

  &.active {
    background-color: $chips-bg-blue;
    border-color: $check-blue;
  }

  &.error {
    border-color: $error-red;
    color: $error-red;
  }
}
