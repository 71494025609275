@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/palette';

.qa {
  border-top: 1px solid $light-gray;

  &:last-child {
    border-bottom: 1px solid $light-gray;
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    min-height: 30px;
    padding: 5px 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;

    .text.bold {
      font-weight: bold;
    }

    .carret {
      & * {
        fill: $nobel-gray;
      }

      transition: transform $transition-time;
      transform: rotate(180deg);

      &.open {
        transform: rotate(0);
      }
    }
  }

  .answer {
    margin: 5px 0 10px;
    font-size: 14px;
    line-height: 1.4;

    @include mobile {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
