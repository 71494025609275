@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.switching-date-section {
  .section-header {
    margin-bottom: 16px;
  }

  .gray {
    .checkbox-container label::before {
      background-color: $regular-gray !important;
    }
  }
  .transaction {
    .amount {
      color: $nobel-green;
    }
  }

  .switching-date {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 4px;
    cursor: pointer;

    .wrapper {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 16px;
      padding-block: 16px;

      .details {
        display: flex;
        flex-direction: column;
        gap: 4px;

        flex-grow: 1;
      }

      .date {
        font-size: 16px;
      }

      .information {
        font-size: 14px;
        color: $regular-gray;
      }

      .carret-color {
        color: $nobel-gray;
      }
    }
  }
}
