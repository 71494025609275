@import 'src/styles/palette';

.standing-order-alert {
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid $light-gray;

  &:last-child {
    border-bottom: none;
  }

  .col-2 {
    margin-right: 16px;
    .name {
      font-size: 16px;
      margin-bottom: 2px;
    }
    .category {
      font-size: 14px;
    }
  }

  .col-3 {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .amount {
      font-size: 16px;
      margin-bottom: 2px;
      white-space: nowrap;
      color: $error-red;
    }
    .frequency {
      text-align: right;
      font-size: 14px;
    }
  }
}
