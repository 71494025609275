@import 'fonts';
@import 'fintec_variables.styles';
@import 'src/styles/screen-types';
@import 'src/styles/palette';

/* bank selection toggle */
.xs2a-form-line.xs2a-tabs {
  ul {
    width: $smallInputWidth;
    display: flex;
    min-height: 35px;
    padding: 0;

    @include mobile {
      width: 100%;

      &:after {
        content: '';
        width: 22px;
        height: 15px;
        margin-left: 15px;
      }
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      vertical-align: middle;
      color: $dark-gray;
      background: $helpTip-gray;
      border: 1px solid $helpTip-gray;

      a {
        font-family: Arial, sans-serif;
        font-size: 16px;
        line-height: 1.3;
        color: $dark-gray;
        text-align: center;
        text-decoration: none;

        @include mobile {
          font-size: getFont(m);
        }
      }

      &.xs2a-selected {
        background: $check-blue;
        border-color: $check-blue;

        a {
          color: $white;
          font-weight: bold;
        }
      }

      &:hover {
        border: 1px solid $regular-gray;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right-width: 0;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left-width: 0;
      }
    }
  }
}
