@import 'src/styles/screen-types';
@import 'src/styles/palette';

.progress-bar {
  background: rgba($black, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .loader-mask {
    display: table;
    margin: 2em auto 0 auto;

    .loader-wrapper {
      display: table-cell;
      vertical-align: middle;
      background-color: $white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      border-radius: 3px;

      @include desktop {
        border: 1px solid $light-gray;
        border-radius: 5px;
      }

      .loader-container {
        box-sizing: border-box;
        padding: 24px 20px;

        @include mobile {
          padding: 10px;
          margin-block: 10px;
          width: calc(100vw - 20px);
        }

        .progress-message {
          font-size: 18px;
          margin-bottom: 15px;
        }

        .progress-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .progress {
            display: flex;
            width: 400px;
            height: 15px;
            background-color: $light-gray;

            .progress-bar-loader {
              width: 0;
              background-color: $progress-blue;
              will-change: transform;
            }
          }

          .progress-percent {
            font-size: 16px;
            text-align: right;
            color: $check-blue;
            margin-left: 10px;
            min-width: 40px;
          }
        }
      }

      .progress-row {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        .process-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-right: 20px;

          @include mobile {
            width: 250px;
          }

          .list-item {
            display: flex;
            align-items: center;

            .check-icon {
              height: 15px;
              width: 20px;
              margin-right: 10px;
              color: $icon-check-gray;

              &.checked {
                color: $check-blue;
              }
            }

            p {
              width: 100%;
              font-size: 14px;
              padding: 0;
              margin: 0;
            }
          }
        }

        img {
          height: 80px;
          width: auto;
        }
      }
    }
  }
}
