@import 'src/styles/palette';

.alert-section {
  .header {
    margin-bottom: 16px;

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .col-1 {
        display: flex;

        .alert-icon {
          width: 22px;
          height: 22px;
          margin-right: 14px;
          color: $alert-orange;
        }
        h2 {
          margin-bottom: 0;
        }
      }
    }

    .subtitle {
      margin-bottom: 16px;
    }
  }

  .child {
    font-size: 16px;

    .bold {
      font-weight: bold;
    }

    ul {
      margin-top: 10px;
      list-style-position: inside;
      margin: 0;
      padding-inline-start: 0;

      & > :first-child {
        margin-top: 16px;
      }
      li {
        margin-top: 5px;
        list-style-type: disc;
        list-style-position: inside;
        text-indent: -1em;
        padding-left: 1em;
      }
    }

    .transaction {
      padding: 0 0 0 20px;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .alert-card-v2 {
    .account-closing-link {
      margin-top: 16px;
      font-size: 16px;
    }
  }
}
