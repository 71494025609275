@import 'fintec_variables.styles';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.xs2a-flicker,
.xs2a-captcha,
.xs2a-text {
  position: relative;
  width: 100%;
  cursor: pointer;

  label {
    position: absolute;
    left: 16px;
    top: 16px;
    color: $regular-gray;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    pointer-events: none;
    letter-spacing: 0.3px;
    font-size: 14px;
    transition: 0.2s ease all;
    width: 80%;

    @include mobile {
      font-size: 18px;
      top: 18px;
    }

    // safari bug about ellipsis - adding nbsp after label
    &:after {
      content: '\0000a0';
      display: inline-block;
      width: 0;
    }
  }

  input {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 17px 16px 0px 16px;
    background-color: $white;
    height: 47px;
    font-size: 14px;
    width: 100%;
    color: $charcoal;
    box-sizing: border-box;

    @include mobile {
      font-size: 18px;
      height: 58px;
    }

    @include desktop {
      &:hover {
        border-color: $dark-gray;
      }
    }

    &:focus {
      border-color: $check-blue;
      background-color: $white;
    }
  }

  &.focused,
  &.has-value {
    label {
      font-size: 12px;
      opacity: 1;
      top: 6px;
      left: 17px;

      @include mobile {
        font-size: 13px;
        color: $check-blue;
      }
    }
  }
}

.xs2a-image + .xs2a-text,
.xs2a-text.full-width {
  // input field next to text -> only one -> full width & no margin right
  width: 100%;
  margin-right: 0;

  @include mobile {
    width: $mobileInputWidth;
  }
}
