@import 'variables';

@mixin mobile {
  @media screen and (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin over-desktop {
  @media screen and (min-width: $desktop-max-width) {
    @content;
  }
}
