@import 'src/styles/palette';

.review-section {
  .header-wrapper {
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      margin-bottom: 0;
    }
  }
  .section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .header {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      & > :not(:first-child) {
        font-weight: normal;
      }
    }
  }
}
