@import 'fintec_variables.styles';

/* flicker specific styling */
.xs2a-flicker {
  width: 100%;
  $flicker-tan-height: 182px;
  $flicker-tan-bottom-margin: 15px;
  $flicker-tan-mobile-additional-bottom-margin: 25px;
  position: relative;

  .xs2a-flicker-container {
    display: flex;
    justify-content: center;
    margin: 0 auto $flicker-tan-bottom-margin auto;
  }
}
