// grays
$light-gray: #dcdcdc;
$nobel-gray: #b4b4b4;
$kings-gray: #d3d3d3;
$alto-gray: #dadada;
$dark-gray: #575757;
$devils-gray: #666;
$regular-gray: #999;
$natural-gray: #808080;
$dim-gray: #696969;
$helpTip-gray: #f4f4f4;
$icon-check-gray: #a9a9a9;
$cta-bg-gray: #fafafa;
$cta-alter-disabled-gray: #f4f4f4;
$cta-border-gray: #ccc;
$cta-disabled-gray: #cdcdcd;

// backgrounds
$desktop-gray: #fafafa;
$mobile-gray: #f4f4f4;
$app-gray: #efeff4;
$alert-gray: #fff6df;

// blues
$check-blue: #005ea8;
$cta-bg-blue: #0271c2;
$cta-bg-hover-blue: #015fa4;
$progress-blue: #1980d0;
$spinner-blue: #063773;
$chips-bg-blue: #ecf7fd;

// reds
$error-red: #c82d2d;
$bubble-red: #e30613;

// oranges
$alert-orange: #f07c00;

// greens
$light-green: #cce7a0;
$nobel-green: #7ab51d;
$success-green: #008300;
$mTan-green: #6ab04c;

// native colors
$white: #fff;
$black: #000;
$charcoal: #333;

// yellows
$highlight-yellow: #ffbb1c;
