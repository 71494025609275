@import 'src/styles/screen-types';
@import 'src/styles/palette';

.container {
  background-color: $white;
  padding: 16px 16px;
  margin-bottom: 8px;

  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }

  @include mobile {
    &:first-child {
      box-shadow: 0px 1px 2px rgba($black, 0.2);
    }

    &:last-child:not(:first-child) {
      margin-bottom: 0;
    }
  }

  @include desktop {
    border-radius: 3px;
    box-shadow: 0px 1px 5px rgba($black, 0.1);
    padding: 24px 48px 32px;
    margin-bottom: 16px;
  }
}
