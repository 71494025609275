@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.notification-details-tab {
  .form-container {
    @include form-container;

    & .switching-date {
      @include switching-date-group;
    }

    .error-text {
      @include error-text;
    }
  }
}
