@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';
@import 'src/styles/mixins/common-input-blocks';

.account-closing-section {
  .section-header {
    margin-bottom: 16px;
  }

  .account {
    display: flex;
    margin-block: 16px;
    &-info {
      flex-grow: 1;
      &__block {
        display: flex;
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
    .col-1 {
      margin-right: 16px;
    }
    .col-2 {
      margin-right: 16px;
      .name {
        font-size: 16px;
        margin-bottom: 2px;
      }
      .category {
        font-size: 14px;
      }
      .action {
        margin-top: 10px;
        font-size: 14px;
        color: $check-blue;
        cursor: pointer;
      }
    }
    .col-3 {
      margin-left: auto;
      .amount {
        text-align: right;
        font-size: 16px;
        margin-bottom: 2px;
        white-space: nowrap;
      }
      .frequency {
        text-align: right;
        font-size: 14px;
      }
    }
  }

  .details-section {
    margin-top: 16px;

    .warnings {
      display: flex;
      gap: 10px;
      align-items: center;

      padding-top: 10px;
      font-size: 14px;

      p {
        font-size: 14px;
      }

      .modified-icon {
        color: $nobel-green;
        width: 15px;
        height: 12px;
      }
    }
  }

  .selection-error {
    @include error-text;
  }
}
