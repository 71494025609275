@import 'src/styles/variables';
@import 'src/styles/screen-types';

.kws {
  max-width: 100%;
  transition: max-width $transition-time;

  &.desktop-side-view {
    @include desktop {
      max-width: 75vw;
    }

    @include over-desktop {
      max-width: 75%;
    }
  }
}
