.login-page {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;

    .kontoumzug-logo {
      width: 200px;
    }
  }
  .unified-login-section {
    padding-bottom: 20px;
    .c24-uli-wrapper {
      background-color: inherit;
      .c24-uli-title {
        display: none;
      }
    }
  }
}
