@import 'fintec_variables.styles';

.xs2a-dialog {
  // rewrite XS2A styles
  margin: 10% 10% !important;
  border-radius: 3px !important;
  background: #f0f0f0 !important;
  border: 0 !important;
  z-index: 99 !important;
  width: 79% !important;
  padding: 5px !important;
}
