@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.redirection-modal {
  .redirection-modal-content {
    .title {
      font-size: 18px;
      margin-bottom: 16px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 24px;
      }
    }

    .sub-title {
      font-size: 16px;
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    .error-text {
      @include error-text;
    }

    .info {
      font-size: 14px;
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      .icon {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        color: $kings-gray;

        @include mobile {
          display: none;
        }
      }
    }

    .buttons {
      margin-right: 0;
      margin-top: 33px;
      max-width: 444px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-left: auto;

      button {
        margin: 0;

        @include desktop {
          &.cta-button {
            min-height: 40px;
          }
        }

        &.secondary {
          @include mobile {
            display: none;
          }
        }
      }
    }
  }
}

// prevents ios app overlap with tab bar
[data-deviceoutput='app'] .redirection-modal .redirection-modal-content {
  @supports (-webkit-touch-callout: none) {
    padding-bottom: 80px;
  }
}
