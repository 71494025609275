@import 'src/styles/palette';

.transition {
  transition: all 0.3s;
}
.w-25p {
  width: 25%;
}
.w-33p {
  width: 33.333333%;
}
.w-50p {
  width: 50%;
}
.w-66p {
  width: 66.66666%;
}
.w-75p {
  width: 75%;
}
.w-full {
  width: 100%;
}

.rotate-0 {
  transform: rotate(0deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-270 {
  transform: rotate(270deg);
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

@media only screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}

.section-header {
  .header-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    h2 {
      margin-bottom: 0;
    }
  }
}
