@import 'src/styles/palette';
@import 'src/styles/common-classes';

[data-deviceoutput='desktop'] {
  .c24-page-container {
    background-color: $desktop-gray !important;
  }

  .c24-footer-copyright {
    a {
      color: $white !important;
    }
  }
}

[data-deviceoutput='mobile'] {
  .c24-page-container,
  .c24-footer-bottom {
    background-color: $mobile-gray !important;
  }
}

[data-deviceoutput='app'] {
  background-color: $app-gray !important;
}

[data-deviceoutput='tabletapp'] {
  .c24-page-container,
  .c24-footer-bottom {
    background-color: $desktop-gray !important;
  }

  .c24-footer-copyright {
    a {
      color: $charcoal !important;
    }
  }
}
