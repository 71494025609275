@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.transaction-details {
  @include border-bottom-extended;
  flex-grow: 1;

  .details {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-block: 16px;
    flex-grow: 1;
    cursor: pointer;

    .information {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        font-size: 16px;
      }

      .category {
        font-size: 14px;
        color: $regular-gray;
      }
    }

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .date {
        font-size: 16px;
      }

      .carret-color {
        color: $nobel-gray;
      }
    }
  }
}
