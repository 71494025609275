@import 'src/styles/palette';

.restricted-page {
  .context {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 15px 50px;

    .blue-logo {
      width: 300px;
    }

    .line {
      margin-top: 30px;
      display: flex;
      align-items: center;

      p {
        font-size: 20px;
      }

      .icon {
        margin-right: 10px;
        width: 25px;
        height: auto;

        &.success {
          color: $nobel-green;
        }
        &.failed {
          color: #d11a2a;
        }
      }
    }
  }
}
