@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.tink-container {
  .tink-widget {
    margin: 1rem 0;

    .xs2a-paragraph.xs2a-none,
    .xs2a-paragraph.xs2a-null {
      @include description-bubble;
    }
  }

  .tink-conditions {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: calc(100vh - 40px);
    }
  }
}
