@import 'src/styles/screen-types';
@import 'src/styles/palette';

.radio-button-container {
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include mobile {
    height: 56px;
  }

  label {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    letter-spacing: normal;
    max-width: 100%;
    font-size: 14px;
    line-height: 54px;
    color: $charcoal;

    @include mobile {
      font-size: 18px;
    }
  }

  &:hover {
    label {
      cursor: pointer;
    }

    input[type='radio'] {
      & + label {
        &:before {
          background-color: $white;
          border-color: $black;
        }
      }
    }
  }

  input {
    display: none;
  }

  input[type='radio'] {
    & + label:before {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      border: 1px solid $nobel-gray;
      background-color: $white;
      content: '';
      display: block;
      float: left;
      margin-right: 8px;
      margin-top: 17px;

      @include mobile {
        height: 20px;
        width: 20px;
        margin-right: 12px;
      }
    }

    &:checked {
      & + label {
        &:before {
          background-color: $white;
          border-color: $check-blue;
        }

        &:after {
          width: 8px;
          height: 8px;
          background-color: $check-blue;
          border-radius: 50%;
          display: block;
          content: '';
          position: absolute;
          bottom: 23px;
          left: 6px;

          @include mobile {
            height: 10px;
            width: 10px;
            bottom: 21px;
            left: 6px;
          }
        }
      }
    }

    &:disabled + label {
      border-color: $light-gray;

      &:before {
        border-color: $light-gray;
      }
    }
  }
}
