@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.cta-button {
  text-decoration: none;
  font-size: 20px;
  padding: 5px 10px;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  border: 0;
  color: $white;
  background-color: $cta-bg-blue;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-height: 32px;
  box-shadow: 0px 2px 3px 0px rgba($black, 0.26);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  transition: background-color $transition-time;

  @include desktop {
    font-weight: bold;
    font-size: 16px;

    &:hover {
      background-color: $cta-bg-hover-blue;
    }
  }

  @include mobile {
    &:active {
      background-color: $cta-bg-hover-blue;
    }
  }

  &.fill {
    min-height: 50px;
    width: 100%;

    @include desktop {
      min-height: 40px;
    }
  }

  &.alternative {
    background-color: $white;
    color: $check-blue;
    border: 1px solid $check-blue;
    border-radius: 3px;
    font-weight: normal;

    &:hover {
      background-color: $cta-bg-gray;
    }

    @include desktop {
      min-height: 40px;

      &:hover {
        background-color: $cta-bg-gray;
      }
    }

    @include mobile {
      &:active {
        background-color: $cta-bg-gray;
      }
    }
  }

  &.secondary {
    background-color: $white;
    color: $devils-gray;
    box-shadow: 0px 2px 3px rgba($black, 0.1);
    margin-top: 10px;
    border: 1px solid $cta-border-gray;

    @include desktop {
      &:hover {
        background-color: $cta-bg-gray;
      }
    }

    @include mobile {
      &:active {
        background-color: $cta-bg-gray;
      }
    }
  }

  &.large {
    font-size: 24px;
    min-height: 50px;
  }

  .spinner {
    @include button-spinner();
  }

  &:hover.disabled,
  &:active.disabled,
  &.disabled,
  &:disabled {
    background-color: $cta-disabled-gray;
    cursor: not-allowed;
  }

  &.blue-spinner {
    &:hover.disabled,
    &:active.disabled,
    &.disabled,
    &:disabled {
      background-color: $cta-alter-disabled-gray;
      box-shadow: 0px 1px 3px 0px rgba($black, 0.1);
    }

    .spinner {
      border-color: rgba($check-blue, 0.2);
      &::before {
        border-color: $check-blue transparent transparent;
      }
    }
  }
}
