@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-detail-pages';

.intro {
  .row-1 {
    display: flex;
    margin-bottom: 4px;
  }
  .col-1 {
    margin-right: 16px;
  }
  .col-2 {
    .name {
      @include details-page-headline;
      margin-bottom: 10px;
    }
    .category {
      font-size: 14px;
      color: $regular-gray;
    }
    .info {
      font-size: 14px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .icon {
        height: 14px;
        width: 14px;
        margin-right: 5px;
        color: $kings-gray;
      }
    }
    .action {
      margin-top: 10px;
      font-size: 14px;
      color: $check-blue;
      cursor: pointer;
    }
  }
  .col-3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    color: $regular-gray;
    font-size: 14px;

    .amount {
      text-align: right;
      margin-right: 6px;
      white-space: nowrap;
    }
    .frequency {
      text-align: right;
      font-size: 14px;
    }
  }
}
