@import 'src/styles/palette';
@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.reset-button {
  width: 100%;
  padding-block: 12px;
  display: inline-flex;
  align-items: center;
  color: $check-blue;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  transition: background-color 0.1s;

  &.sticky {
    @include sticky-from-top;
    background-color: $white;
  }

  &.shadow {
    @include mobile {
      box-shadow: 0px 1px 2px rgba($black, 0.2);
    }
  }

  @include desktop {
    width: max-content;
    padding-block: 8px;
    padding-right: 40px;
    font-size: 14px;

    margin: 6px 0 16px;
  }

  svg {
    margin-left: 16px;
    margin-right: 8px;
  }

  .blue-spinner {
    @include button-spinner(rgba($check-blue, 0.2), $check-blue);
  }
}
