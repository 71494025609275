@import 'src/styles/palette';

.auto-complete {
  width: 100%;

  ~ .modal-wrapper {
    .modal-overlay {
      &,
      .modal.full {
        overflow: scroll;
      }
    }
  }
}

.selection-modal {
  padding-bottom: 10px;

  .search-field {
    margin-inline: 10px;

    .possible-list {
      margin-block: 10px;
      border: 1px solid $alto-gray;
      border-radius: 5px;
      width: 100%;

      .possible-item {
        cursor: pointer;
        border-bottom: 1px solid $alto-gray;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
