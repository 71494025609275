@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.chip-button-group {
  .group-label {
    @include form-label;
  }

  .chip-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
