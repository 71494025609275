@import 'src/styles/palette';

.alert-card {
  position: relative;
  margin: 0 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid $alert-orange;
  background-color: $alert-gray;

  ul {
    margin-top: 10px;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
  }

  .header {
    color: $alert-orange;
    font-size: 16px;
    font-weight: bold;
    padding-right: 50px;
    margin-bottom: 11px;
  }

  .text {
    font-size: 16px;
  }
}
