@import 'src/styles/palette';

.full-page-spinner {
  background-color: $white;
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .spinner {
    width: 50px;
    height: 50px;
    border: 10px solid;
    border-color: $spinner-blue transparent $spinner-blue transparent;
    border-radius: 50%;
    animation: spin 1.8s linear infinite;
  }

  .text {
    margin: 15px 30px 0;
    color: $check-blue;
    font-size: 18px;
    text-align: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
