@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/variables';

@layer kws-body {
  .kws-body {
    h2 {
      font-size: 20px;
      font-weight: normal;
      padding: 0;
      margin: 0;
    }
    p {
      font-size: 16px;
      margin: 0;
    }
    box-sizing: border-box;
    max-width: $desktop-max-width;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
    color: $charcoal;
    padding: 10px 20px;
  }

  @include mobile {
    .kws-body {
      max-width: none;
      width: auto;
      padding: 0;
      border: 0px;
      margin: 0;
    }
  }
}
