@import 'src/styles/screen-types';
@import 'src/styles/palette';

.success-section {
  .success {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .success-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .text {
        font-size: 20px;
        color: $success-green;

        @include desktop {
          font-size: 22px;
        }
      }
    }
  }

  .title {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
    color: $charcoal;

    span {
      font-weight: bold;
    }
  }

  .account-info {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include desktop {
      gap: 8px;
    }

    .row {
      display: grid;
      grid-template-columns: 180px 1fr;
      font-size: 16px;

      @include desktop {
        grid-template-columns: 200px 1fr;
      }

      .data {
        font-weight: bold;
      }
    }
  }
}

.success-info-box-wrapper {
  margin-top: 16px;
}
