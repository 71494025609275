@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.birthday-check {
  p {
    font-size: 16px;
    margin: 10px 0;
  }

  .date-row {
    margin-bottom: 10px;
  }

  @include desktop {
    @include cta-button-center;
  }
}
