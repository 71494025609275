@import 'fintec_variables.styles';
@import 'src/styles/palette';

.xs2a-radio {
  .xs2a-radio-label {
    height: 47px;
    margin-left: 15px;
    font-size: $labelFontSize;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 15px;

    @include mobile {
      font-size: 20px;
    }
  }

  & > div:not(.xs2a-radio-label) {
    position: relative;
    height: 47px;
    display: flex;
    align-items: center;

    label {
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      letter-spacing: normal;
      max-width: 100%;
      font-size: 14px;
      line-height: 54px;
      color: $charcoal;
      cursor: pointer;

      @include mobile {
        font-size: 18px;
      }
    }

    &:hover {
      label {
        cursor: pointer;
      }

      input[type='radio'] {
        & + label {
          &:before {
            border-color: $black;
          }
        }
      }
    }

    input {
      display: none;
    }

    input[type='radio'] {
      & + label:before {
        content: '';
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 1px solid $nobel-gray;
        background-color: $white;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 17px;
        cursor: pointer;
      }

      &:checked {
        & + label {
          position: relative;

          &:before {
            background-color: $white;
            border-color: $check-blue;
          }

          &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: $check-blue;
            display: block;
            bottom: 22px;
            left: 5px;
            border-radius: 50%;
          }
        }
      }

      &.xs2a-invalid + label:before {
        border: 1px solid $error-red;
      }

      &:disabled + label {
        border-color: $light-gray;

        &:before {
          border-color: $light-gray;
        }
      }
    }
  }
}
