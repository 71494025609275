@import 'fintec_variables.styles';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.fintec-form {
  & > div:not([class^='xs2a']) {
    height: 0 !important;
    display: none !important;
    visibility: hidden;
  }

  input {
    &[type='radio'] {
      -webkit-appearance: auto !important;
      margin: 0 5px;
    }

    &::placeholder {
      color: transparent !important;
    }
  }

  .xs2a-description {
    font-family: verdana, helvetica, arial, sans-serif;
    font-size: 14px;
    color: $devils-gray;
  }

  .xs2a-paragraph > * {
    line-height: 1.5;
  }

  .xs2a-completion-results {
    z-index: 10;
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding: 4px;
    box-sizing: border-box;
    background-color: white;
    .xs2a-completion-result {
      padding: 8px;
    }

    .xs2a-completion-result-active {
      background-color: $light-gray;
    }
  }

  .xs2a-spinner {
    display: none;
  }
}

/* generic layout for all form elements */
.xs2a-form-line {
  width: 100%;
  margin-bottom: 10px;
}

.has-multiform-radio {
  .xs2a-form-line {
    &.xs2a-radio {
      width: 100%;
      margin-bottom: 10px;
    }

    &.xs2a-paragraph {
      p {
        @include mobile {
          padding-left: 15px;
        }

        display: inline;
        font-size: getFont(s);
        color: $devils-gray;
      }
    }
  }
}

.fintec-with-select {
  @include mobile {
    margin-bottom: 10px;
  }
}

.has-multicredential-form {
  .xs2a-form-line {
    &:first-child {
      @include mobile {
        margin-top: 5px;
      }
    }

    &.xs2a-text {
      @include desktop {
        margin-bottom: $distanceBetweenRows;
      }
    }
  }
}
