@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';
@import 'src/styles/mixins/common-blocks';
@import 'src/styles/mixins/common-detail-pages';

.switchingdate-details {
  & > .container.transparent {
    padding: 0 16px 44px;

    @include desktop {
      padding-bottom: 48px;
    }
  }

  .intro-wrapper {
    @include detail-pages-intro-container;
  }

  .switchingdate-details-header {
    display: flex;
    justify-content: space-between;
    @include details-page-headline;

    .title {
      margin-right: 16px;
      color: $charcoal;
    }

    .date {
      text-align: right;
      white-space: nowrap;
    }
  }

  .form-container {
    & .switching-date {
      @include switching-date-group;
    }

    @include form-container;
    margin-top: 16px;
  }

  .transactions-title {
    margin-bottom: 16px;
    margin-top: 44px;
    font-size: 20px;
    color: $charcoal;

    @include desktop {
      margin-top: 48px;
    }
  }

  .description {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .warnings {
    display: flex;
    gap: 10px;
    align-items: center;

    padding-top: 10px;
    font-size: 14px;

    p {
      font-size: 14px;
    }

    .modified-icon {
      color: $nobel-green;
      width: 15px;
      height: 12px;
    }
  }

  .switching-alert-content {
    @include confirmation-modal-content;
  }
}
