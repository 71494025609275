@import 'src/styles/screen-types';

.default-success-section {
  .default-section-header {
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop {
      margin-top: 16px;
    }
  }

  & > .default-section-header:first-child {
    margin-top: 0;
  }
}
