.welcome-banner-section {
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .text-description {
    margin-top: 14px;
  }
}
