@import 'src/styles/screen-types';
@import 'src/styles/palette';

.info-box {
  position: relative;
  border: 1px solid $regular-gray;
  border-radius: 5px;
  border-left-width: 8px;
  padding: 16px;

  background-color: $white;

  &.alert {
    border-color: $alert-orange;
  }

  &.success {
    border-color: $nobel-green;
  }

  &.info-with-icon {
    border-color: $check-blue;
  }

  &.info {
    border-color: $check-blue;
    font-size: 14px;
    color: $charcoal;

    p {
      font-size: 14px;
    }
  }

  .alert-icon {
    width: 20px;
    height: 20px;
  }

  @include desktop {
    display: flex;
    align-items: flex-end;
    gap: 50px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: $nobel-gray;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .info-box-header {
      display: flex;
      align-items: center;
      font-weight: bold;

      p {
        margin-left: 10px;
        padding-right: 20px;
      }
    }

    .info-box-header:only-child {
      font-weight: normal;
    }
  }

  .info-box-content > * {
    font-size: 14px;
  }

  .info-box-content > :not(:first-child) {
    margin-top: 10px;
  }

  button {
    margin-right: 0;
    margin-top: 16px;
    height: 40px;
    min-width: 200px;

    @include desktop {
      max-width: 200px;
    }
  }
}
