@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/variables';

.landing-page {
  p {
    margin: 0;
  }

  .container {
    &:last-child {
      margin-bottom: 0;
    }

    @include desktop {
      max-width: $desktop-max-width;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 40px !important;
    }
  }

  &.product-info-app {
    padding-bottom: 60px;
  }

  @include desktop {
    font-family: Verdana;
    h2 {
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
    }
  }

  @include mobile {
    background-color: $app-gray;
    font-family: Arial;
    h2 {
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
    }
  }
}
