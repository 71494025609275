@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.mTan-check {
  .inner-wrapper {
    padding: 15px;
    text-align: center;
  }

  @include desktop {
    @include cta-button-center;
  }

  .title {
    font-size: 20px;
    line-height: 23px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    margin: 20px 0 15px;

    .sms-pin {
      height: 85px;
    }
  }

  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .pin-title {
    margin-bottom: 6px;
    font-size: 14px;
  }

  .pin-field-container {
    display: flex;
    max-width: 500px;
    margin: auto;

    &.error {
      .pin-field {
        border-color: $error-red !important;
      }
    }

    .pin-field {
      border: 1px solid $kings-gray;
      border-right: none;
      font-size: 2rem;
      height: 60px;
      outline: none;
      text-align: center;
      transition-duration: 250ms;
      transition-property: color, border, box-shadow, transform;
      width: 100%;

      &:first-of-type {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-of-type {
        border-radius: 0 0.5rem 0.5rem 0;
        border-right: 1px solid $kings-gray;
      }

      &:focus {
        border-color: $spinner-blue;
        box-shadow: 0 0 0.25rem rgba($spinner-blue, 0.5);
        outline: none;

        & + .pin-field {
          border-left-color: $spinner-blue;
        }
      }

      &:invalid {
        animation: shake 5 linear 75ms;
        border-color: $error-red;
        box-shadow: 0 0 0.25rem rgba($error-red, 0.5);

        & + .pin-field {
          border-left-color: $error-red;
        }
      }

      &.complete {
        border-color: $mTan-green;
        color: $mTan-green;

        &[disabled] {
          background: rgba($mTan-green, 0.1);
          cursor: not-allowed;
          opacity: 0.5;
        }

        & + .pin-field {
          border-left-color: $mTan-green;
        }
      }
    }
  }

  .message {
    font-size: 14px;
    margin-top: 10px;
    color: $error-red;
  }

  .resend-sms {
    font-size: 14px;
    margin: 10px 0 10px;
    color: $kings-gray;
    cursor: pointer;
    &:hover {
      color: $check-blue;
    }
  }

  .resend-message {
    margin: 0 0 30px;
    font-size: 14px;
    color: $mTan-green;
  }

  @keyframes shake {
    from {
      transform: translateY(-5%);
    }
    to {
      transform: translateY(5%);
    }
  }
}
