@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.test-page {
  padding-inline: 10px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .bank-selection {
    padding: 10px 16px 0;
    border: 1px solid $light-gray;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: $white;
    p {
      margin-bottom: 10px;
    }
  }

  .input-row {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;

    &.checkbox {
      display: flex;
      height: 34px;
      padding-left: 20px;
      flex-direction: column;

      p {
        padding: 0;
        margin: 0;
      }

      .label {
        color: $devils-gray;
        font-size: 11px;
      }
    }
  }

  @include desktop {
    @include cta-button-center;
  }
}
