@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.tooltip-wrapper {
  position: relative;
  margin-left: 10px;

  display: flex;
  align-items: center;
  color: $nobel-gray;

  .help-icon {
    cursor: pointer;
    color: $regular-gray;

    transition: color $transition-time;

    @include desktop {
      color: $nobel-gray;
    }

    &.focused {
      color: $charcoal;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    color: $dark-gray !important;
    top: -10px;
    width: max-content;
    max-width: 20vw;
    padding: 0.75rem;
    height: max-content;
    left: 68px;
    z-index: 3;
    direction: rtl;

    .title {
      font-size: 12px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    & p {
      font-size: 12px;
      line-height: 1.4;
      direction: ltr;
      text-align: left;
      margin: 0;
      color: $charcoal;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 17px solid rgba(0, 0, 0, 0.1);
      position: absolute;
      left: -17px;
      top: 10px;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 24px solid $desktop-gray;
      position: absolute;
      left: -15px;
      top: 5px;
    }

    &.detail-page {
      border-radius: 3px;
      background-color: $white;
      border: 1px solid rgba($black, 0.1);
      max-width: 14vw;

      &::after {
        border-right: 24px solid $white;
      }
    }

    // order matters, needs to stay in the bottom
    @include over-desktop {
      max-width: 300px;
    }
  }

  .tooltip.tooltip-shown {
    display: block;
  }

  .overlay {
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    overflow: hidden;

    &.shown {
      visibility: visible;
      opacity: 1;
    }

    p {
      color: $charcoal;
    }
  }

  .popup {
    position: relative;
    margin: auto 50px;
    padding: 20px;
    background: $white;
    border-radius: 3px;
    max-height: 60%;
    top: 20%;
    margin: auto 15px;
    color: $charcoal;

    .close {
      position: absolute;
      top: -30px;
      right: 0;
      transition: all 200ms;
      font-size: 20px;
      color: $white;

      &:hover {
        cursor: pointer;
      }
    }

    .content {
      box-sizing: border-box;
      max-height: 400px;
      overflow: scroll;
      line-height: 1.4;

      p {
        color: $charcoal;
      }
    }
  }
}
