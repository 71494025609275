@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.tab-wrapper {
  @include desktop {
    border-top: 1px solid $light-gray;
  }

  .tabs-section {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    font-size: 16px;
    line-height: 1.25;
    color: $charcoal;

    background-color: $white;
    box-shadow: 0px 1px 2px rgba($black, 0.2);

    @include desktop {
      border-radius: 0 0 3px 3px;
      box-shadow: 0px 1px 5px rgba($black, 0.1);
    }

    .tab-item {
      cursor: pointer;
      position: relative;
      padding-block: 12px;
      width: 100%;
      text-align: center;

      @include desktop {
        min-width: 200px;
        max-width: max-content;
      }

      @include mobile {
        &:not(:first-child)::before {
          position: absolute;
          left: 0;
          top: 10px;
          content: '';

          width: 1px;
          height: 24px;
          background-color: $light-gray;
        }
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 16px;

        content: '';
        height: 2px;
        width: 16px;

        border-radius: 1px;
        transition: all $transition-time;

        @include desktop {
          left: 0;
        }
      }
    }

    .underline {
      color: $check-blue;

      &::after {
        width: calc(100% - 32px);
        background-color: $check-blue;
      }

      @include desktop {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
