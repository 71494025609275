@import 'src/styles/screen-types';
@import 'src/styles/palette';

.toast-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid $nobel-green;
  border-left-width: 8px;
  border-radius: 6px;
  padding: 16px;
  background-color: $white;

  &.removed {
    border: 1px solid $regular-gray;
    border-left-width: 8px;
  }

  .icon {
    width: 20px;
    height: 20px;
    color: $charcoal;
  }

  .header {
    display: flex;
    align-items: center;

    p {
      margin-left: 10px;
    }
  }

  p {
    font-size: 14px;
  }
}

.toast-wrapper {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: calc(100% - 32px);

  @include desktop {
    max-width: 400px;
  }
}
