@import 'src/styles/palette';

.account-closing {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-block: 16px;

  .title {
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .info {
    font-size: 14px;
    color: $regular-gray;
  }
}
