@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.manual-kws {
  .description-bubble {
    @include description-bubble;
  }

  button {
    margin-top: 20px;
    margin-right: 0;
    height: 40px;
    min-width: 200px;

    p {
      margin-left: 10px;
      @include desktop {
        max-width: 200px;
      }
    }
  }

  .text-input {
    margin-bottom: 10px;
  }

  .manual-transfer-legal {
    font-size: 12px;
  }

  @include desktop {
    @include cta-button-center;

    .cta-button {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
