@import 'src/styles/screen-types';
@import 'src/styles/palette';

.welcome-section-dac {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .kontoumzug-logo {
    width: 70px;
    height: 100%;

    @include desktop {
      width: 100px;
      height: 100%;
    }
  }

  .title {
    font-size: 20px;
    color: $charcoal;
    text-decoration: none solid rgb(51, 51, 51);
    font-weight: bold;

    @include desktop {
      font-size: 22px;
    }
  }

  .subtitle {
    font-size: 20px;
    margin-top: 5px;
    color: $charcoal;
    text-decoration: none solid rgb(51, 51, 51);

    @include desktop {
      margin-top: 10px;
      font-size: 22px;
    }
  }

  .text {
    font-size: 16px;
    color: $charcoal;
    text-decoration: none solid rgb(51, 51, 51);
    margin-top: 20px;

    @include desktop {
      margin-top: 24px;
    }
  }
}
