@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.reset-section {
  .reset-button {
    width: 100%;
    padding-block: 16px;
    display: inline-flex;
    align-items: center;
    color: $check-blue;
    cursor: pointer;
    font-size: 16px;

    @include desktop {
      width: max-content;
      padding-block: 8px;
      padding-right: 40px;
      font-size: 14px;

      margin: 6px 0 16px;
    }

    svg {
      margin-left: 16px;
      margin-right: 8px;
    }
  }
  .confirmation-modal-content {
    @include confirmation-modal-content;
  }
}
