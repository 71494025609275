@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.missing-iban-section {
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .alert-icon {
      width: 22px;
      height: 22px;
      margin-right: 14px;
      color: $alert-orange;
    }
    h2 {
      margin: 0;
    }
  }
  .text {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .button {
    margin-top: 16px;
  }

  @include desktop {
    @include cta-button-center;
  }
}
