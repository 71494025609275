@import 'src/styles/palette';

.all-transactions-tab {
  padding-inline: 16px;

  .title {
    margin-bottom: 16px;
  }

  .transaction-item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding-block: 16px;
    font-size: 16px;
    color: $charcoal;

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray;
    }

    .header {
      width: 100%;
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .date {
          color: $regular-gray;
          font-size: 14px;
        }
      }
      .right {
        width: 100px;
        .amount {
          white-space: nowrap;
          float: right;
        }
      }
    }

    .description {
      font-size: 14px;
    }
  }

  &:last-child {
    margin-bottom: 24px;
  }
}
