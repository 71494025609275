@import 'src/styles/screen-types';
@import 'src/styles/palette';

.contact-card {
  a {
    color: $charcoal;
  }
  h2 {
    margin: 0;
    margin-bottom: 20px;
  }
  .contact-item {
    margin-bottom: 16px;
    font-size: 16px;

    .contact-block {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-top: 1px solid $light-gray;
      font-size: 16px;

      &:first-child {
        border-top: none;
      }

      svg {
        margin-right: 20px;
        margin-left: 4px;
      }

      .subtext {
        margin-top: 5px;
        color: $regular-gray;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
