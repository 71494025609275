@import '../palette';
@import '../screen-types';
@import '../variables';

@mixin auto-complete-item {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 16px;
  padding: 10px 16px;
  transition: background-color $transition-time;

  @include desktop {
    gap: 16px;

    &:hover {
      background-color: $alto-gray;
    }
  }
}

@mixin auto-complete-item-logo($fillColor: $alto-gray) {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $fillColor;

  img,
  svg {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}

@mixin auto-complete-item-details {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > :first-child {
    font-size: 16px;
    color: $charcoal;
  }

  > :nth-child(2) {
    font-size: 14px;
    line-height: 1;
    color: $regular-gray;

    @include mobile {
      line-height: 1.25;
    }
  }
}
