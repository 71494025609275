@import 'src/styles/palette';

.xs2a-abort-line,
.xs2a-submit-line,
.xs2a-restart-line,
.xs2a-submit-line {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;

  @include mobile {
    flex-direction: column-reverse;
    align-items: center;
  }

  button {
    text-decoration: none;
    font-size: 18px;
    padding: 5px 10px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    text-transform: lowercase;
    border: 0;
    color: $white;
    background: $cta-bg-blue;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.26);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    min-height: 40px;
    width: 100%;
    max-width: 250px;

    @include mobile {
      font-weight: normal;
      font-size: 20px;
      max-width: none;
      min-height: 50px;
    }

    &:hover {
      background-color: $cta-bg-hover-blue;
    }

    &.xs2a-back,
    &.xs2a-abort,
    &.xs2a-restart {
      background: $white;
      color: $devils-gray;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      border: 1px solid $cta-border-gray;

      &:hover {
        background-color: $cta-bg-gray;
      }
    }

    .spinner {
      position: relative;
      width: 15px;
      height: 15px;
      border: 4px solid;
      margin: 0;
      margin-left: 10px;
      border-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      &::before {
        content: '';
        position: absolute;
        width: 15px;
        left: -4px;
        top: -4px;
        height: 15px;
        border: 4px solid;
        margin: 0;
        border-color: $white transparent transparent;
        border-radius: 50%;
        animation: spin 1.8s linear infinite;
      }
    }

    &:hover.disabled,
    &:active.disabled,
    &.disabled,
    &:disabled {
      background-color: $cta-disabled-gray;
      cursor: not-allowed;
    }
  }
}
