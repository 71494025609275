@import 'src/styles/palette';
@import 'src/styles/variables';

.state-info-chip {
  position: relative;
  color: $charcoal;

  .state-label {
    background-color: $light-gray;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    width: max-content;
    border-radius: 10px;

    font-size: 14px;
    line-height: 16px;

    &.alert {
      background-color: rgba($alert-orange, 0.15);
      color: $alert-orange;
    }

    &.success {
      background-color: rgba($success-green, 0.15);
      color: $success-green;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .state-modal-content {
    padding: 20px;
    font-size: 16px;
  }
}
