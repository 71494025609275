@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.text-input {
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .floating-label {
      color: $regular-gray;
      position: absolute;
      pointer-events: none;
      left: 16px;
      top: 16px;
      font-size: 14px;
      transition: 0.2s ease all;

      @include mobile {
        font-size: 18px;
        top: 18px;
      }
    }

    input {
      height: 47px;
      font-size: 14px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 16px 16px 0px 16px;
      background-color: $white;
      width: 100%;
      color: $charcoal;
      box-sizing: border-box;
      transition: 0.2s ease border-color;

      @include mobile {
        font-size: 18px;
        height: 58px;
        padding-right: 42px;
      }

      @include desktop {
        &:hover {
          border-color: $dark-gray;
        }
      }

      &:focus {
        border-color: $check-blue;
        background-color: $white;
      }

      &:focus ~ .floating-label,
      &.has-value ~ .floating-label {
        font-size: 12px;
        top: 6px;
        left: 17px;
        opacity: 1;

        @include mobile {
          font-size: 13px;
        }
      }

      &.has-value ~ .floating-label {
        color: $regular-gray;
        transition: none;
      }

      @include mobile {
        &:focus ~ .floating-label {
          color: $check-blue;
        }
      }
    }

    .append-inner {
      height: 100%;
      display: flex;
      align-items: center;
      right: 16px;
      position: absolute;
      color: $nobel-gray;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .error-text {
    @include error-text;
  }

  &.error {
    input {
      border-color: $error-red;
    }
  }

  &.disabled {
    input,
    input:hover,
    input:focus {
      border-color: $light-gray;
      background-color: $cta-bg-gray;
      color: $devils-gray;
      cursor: default;

      @include mobile {
        background-color: $cta-alter-disabled-gray;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
