@import 'fonts';
@import 'src/styles/palette';

$mobileInputWidth: 90%;
$label-focus-offset: 12px;
$smallInputWidth: 240px;
$labelFontSize: 13px;
$labelMobileLeftSpace: 15px;

$distanceBetweenRows: 20px;
$distanceBetweenRowsMobile: $distanceBetweenRows + 15px;

@mixin fintec-error {
  font-size: getFont(xxs);
  letter-spacing: normal;
  color: $error-red;
  margin-top: 6px;
}
