@import 'src/styles/screen-types';
@import 'src/styles/palette';

.modal-wrapper {
  .modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 500ms;
    overflow: hidden;

    .modal {
      .modal-content {
        width: 100%;
      }

      .modal-close {
        position: absolute;
        top: -30px;
        right: 0;
        font-size: 20px;
        color: $white;

        &:hover {
          cursor: pointer;
        }
      }

      background: $white;

      @include desktop {
        position: relative;
        padding: 20px;
        border-radius: 3px;
        max-height: 60vh;
        top: 30%;
        margin: auto;
        max-width: 498px;

        &.bottom,
        &.full {
          max-width: 658px;
        }
      }

      @include mobile {
        &.centered {
          position: relative;
          margin: 10px;
          border-radius: 5px;
          max-height: 60vh;
          top: 50%;
          transform: translateY(-50%);
          max-width: 658px;
        }

        &.bottom {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          position: fixed;
          bottom: 0;
          width: 100%;
          padding: 24px 12px;
          transition: all 0.3s;
          box-sizing: border-box;
          display: flex;
          justify-content: center;

          .modal-close {
            right: 15px;
          }
        }

        &.full {
          background-color: $white;
          width: 100%;
          height: 100%;
          z-index: 1000;
          position: fixed;
          top: 0;
          left: 0;
          overflow: hidden;

          .modal-header {
            margin: 10px;
            display: flex;
            justify-content: flex-end;

            .modal-close {
              top: auto;
              position: relative;
              color: $regular-gray;
            }
          }
        }
      }
    }
  }
}
