@import 'fintec_variables.styles';

/* QR codes, flicker codes */
.xs2a-image {
  img {
    display: block;
    margin: 0 auto;
    width: 300px;
  }
}
