@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.alert-card-v2 {
  @include border-bottom-extended;

  .alert-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-block: 16px;
  }

  .alert-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .alert-title-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .alert-icon {
      width: 20px;
      height: 20px;
      color: $alert-orange;
    }

    .alert-title.bold {
      font-weight: bold;
    }

    .carret {
      color: $regular-gray;
      transform: rotate(180deg);
      transition: transform $transition-time;
      cursor: pointer;
      &.open {
        transform: rotate(0);
      }
    }
  }

  .alert-body {
    margin-top: 16px;
  }
}
