@import 'src/styles/palette';
@import 'src/styles/variables';

.hover-text {
  position: absolute;
  top: 32px;
  z-index: 99;
  max-width: 360px;
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba($black, 0.3);
  transition: opacity $transition-time;

  .top-arrow {
    position: absolute;
    top: -8px;
    left: 40px;
    z-index: -1;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    box-shadow: 0px 1px 8px rgba($black, 0.3);
    background-color: $white;
  }

  .content {
    padding: 16px;
    border-radius: 5px;
    background-color: $white;
    font-size: 14px;
    line-height: 1.5;
  }
}
