@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/variables';

.entry-section {
  background-color: $app-gray;

  .wrapper {
    padding: 16px 16px;

    @include desktop {
      max-width: $desktop-max-width;
      margin-right: auto;
      margin-left: auto;
      padding: 32px 10px;
    }

    .content {
      h2 {
        margin-top: 0;
      }

      .entries {
        margin: 20px 0 12px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
    }

    .phone-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      img {
        width: 100%;
      }
    }

    @include desktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .phone-image {
        width: 265px;
        height: auto;
        padding: 0;
        margin-left: 20px;
        align-items: baseline;
      }
    }
  }
}
