@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.notifications-section {
  .dac-alert {
    display: flex;
    align-items: center;

    & > p {
      margin: 16px 10px;
    }

    & > :first-child {
      margin-top: 16px;
    }
  }

  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 16px;

    h2 {
      font-size: 18px;

      @include desktop {
        font-size: 20px;
      }
    }
  }

  .sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    margin-bottom: 16px;

    svg {
      margin-right: 6px;
      width: 23px;
    }
  }

  .notifications {
    .sort {
      margin-bottom: 0;
    }
  }

  .partners {
    margin-top: 24px;

    .text {
      margin-bottom: 16px;
      font-size: 16px;
    }

    .partner {
      .header {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin-bottom: 16px;
          font-size: 18px;

          @include desktop {
            font-size: 20px;
          }
        }

        .carret {
          color: $nobel-gray;
          transition: transform $transition-time;
        }
      }

      &:first-child {
        .header {
          padding-top: 0;
        }
      }

      .notifications.hide {
        height: 0;
        overflow: hidden;
      }

      .transaction:first-child {
        padding-top: 0;
      }
    }
  }

  .info-box-wrapper {
    margin-top: 16px;
  }

  .add-notification {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .toggle-2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: $check-blue;
    margin-top: 16px;
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    svg {
      margin-left: 6px;
    }
    .carret {
      transition: transform $transition-time;
    }
  }
}
