@import 'src/styles/screen-types';
@import 'src/styles/palette';

.landing-entry {
  margin-bottom: 24px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;

    .number {
      height: 30px;
      width: 30px;
      border: 2px solid $charcoal;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      margin-left: 10px;
    }
  }

  .description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;

    @include desktop {
      font-size: 14px;
    }

    .detail {
      cursor: pointer;
      color: $check-blue;
      white-space: nowrap;

      .carret {
        transform: rotate(180deg);
      }
    }
  }

  .entry-list {
    ul {
      list-style-position: unset;
      padding-left: 15px;
      li {
        list-style-type: none;
        margin-top: 10px;
        text-indent: 0;
        padding-left: 0;
        font-size: 16px;
        line-height: 20px;

        @include desktop {
          font-size: 14px;
        }

        &:before {
          content: '';
          float: left;
          display: list-item;
          list-style-type: square;
          list-style-position: inside;
          width: 15px;
          margin-left: -15px;
          line-height: 18px;
          color: $check-blue;
        }
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;

    img {
      width: 100%;
    }

    @include desktop {
      width: 264px;
      padding: 15px 0;
      justify-content: flex-start;
    }
  }

  .closing-tag {
    display: flex;
    justify-content: flex-end;
    font: normal normal normal 16px/20px Arial;
    margin-top: 5px;
    color: $check-blue;
    padding-right: 10px;
    cursor: pointer;

    @include desktop {
      margin-top: 12px;
      justify-content: start;
    }
  }
}
