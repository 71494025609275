@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.invalid-dates-card {
  .header {
    margin-bottom: 16px;

    .flex-wrapper {
      display: flex;
      align-items: center;

      .alert-icon {
        width: 22px;
        height: 22px;
        margin-right: 14px;
        color: $alert-orange;
      }
      h2 {
        margin: 0;
      }
    }
  }

  .section {
    .header {
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 5px;
      border-bottom: 1px solid $light-gray;
      margin-top: 1rem;
    }
  }
  .row {
    display: flex;
    width: 100%;
  }
  .text {
    font-size: 16px;
  }
  .notifications {
    margin-top: 1rem;
  }
  .button {
    margin-top: 1rem;
  }
  .toggle {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    color: $check-blue;
    cursor: pointer;
    svg {
      transition: transform $transition-time;
    }
  }
  .buttons {
    margin-top: 1rem;

    .cancel {
      margin-top: 10px;
      background: none;
      background-color: $white;
      color: $devils-gray;
      border: 1px solid $cta-border-gray;
    }
  }
  .edit-date {
    padding-bottom: 21px;
    padding-left: 23px;
    padding-right: 17px;
    background: $alert-gray;
  }

  @include desktop {
    @include cta-button-center;
  }
}
